* {
  margin: 0;
  box-sizing: border-box;
}

:root {
  --primary-color: #3A71A4;
  --font-color: #37474f;
}

.container {
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 15px;
}

/* dashboard main layout */

.dashboard-main-container {
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  top: 100px;
  padding-bottom: 50px;
  overflow: auto;
  min-height: calc(100vh - 250px);
}

.challenge-list-container {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
}

.challenge-card-container {
  width: 100%;
  filter: drop-shadow(4px 4px 4px rgba(68, 68, 68, 0.1));
  background-color: #fff;
  cursor: pointer;
}

.challenge-card-info {
  padding: 10px;
  background-color: #fff;
  cursor: pointer;
}

.challenge-card-type {
  padding: 8px 15px;
  background-color: #EBEBEB;
}

.challenge-card-image-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 56.25%;
}

.image-toggle-container {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(9, 78, 142, 0.5);
  box-shadow: 4px 4px 5px 2px rgba(88, 88, 88, 0.2);
  border-radius: 9px;
  padding: 5px 0 0 15px;
}

.toggle-icon {
  margin-right: 15px;
  height: 25px;
}

.challenge-card-sub-container {
  display: grid;
  margin: 10px 0;
  align-items: center;
  grid-template-columns: auto auto 1fr;
  gap: 15px;
}

.challenge-card-title {
  font-size: 1.2rem;
  font-weight: bold;
  margin: 10px 0;
  color: var(--font-color);
}

.challenge-card-type {
  border-radius: 5px;
  display: flex;
  align-items: center;
  color: var(--font-color);
  font-weight: bold;
}

.challenge-card-js {
  line-height: 26px;
  font-size: 1.25rem;
  font-weight: bold;
  color: #939601;
}

.challenge-card-date {
  text-align: right;
  color: var(--font-color);
}

.challenge-card-image {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}

.challenge-card-title-container {
  display: flex;
  justify-content: space-between;
}

.challenge-card-pending {
  color: #EF870C;
}

.challenge-card-container {
  position: relative;
}

.challenge-card-submission {
  position: absolute;
  top: -10px;
  left: 10px;
  padding-top: 15px;
  background-image: url('/src/assets/icons/submission-vector.svg');
  width: 100px;
  height: 100px;
  background-repeat: no-repeat;
  text-align: center;
  font-size: 1.2rem;
  color: #fff;
}

.challenge-card-desc {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: var(--font-color);
}

.type-symbol {
  height: 26px;
  margin-right: 15px;
}

/* header styles */
.header-container {
  position: fixed;
  width: 100%;
  z-index: 5;
  background-color: #fff;
  box-shadow: 0px 4px 10px rgba(111, 111, 111, 0.1);
  padding: 5px 15px;
}

.footer-container {
  width: 100%;
  padding: 5px 15px;
  position: relative;
  bottom: -100px;
  margin-top: 50px;
  margin: 0 auto;
  box-shadow: 0px -4px 10px rgba(111, 111, 111, 0.1), 0px 4px 10px rgba(111, 111, 111, 0.1);
}

.sponser-link {
  color: var(--font-color);
}

.footer-main-section {
  width: 100%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 200px;
  grid-template-rows: 1fr 1fr;
  grid-template-areas: "sponser social"
    "copyright social";
  gap: 15px;
  max-width: 1270px;
}

.social-media-container {
  display: flex;
  gap: 20px;
  grid-area: social;
  place-self: center;
}

.sponsers {
  grid-area: sponser;
  align-self: center;
  cursor: pointer;
  color: #4D4D4D;
  font-weight: 600;
  letter-spacing: 0.8px;
  font-size: 1.2rem;
}

.sponsers-logos {
  display: grid;
  grid-auto-flow: column;
  gap: 20px;
  height: 50px;
}

.sponser-logo-small {
  height: 50px;
  width: 50px;
}


.sponsers:hover {
  color: var(--primary-color);
}

.copyright-container {
  border-top: 1px solid var(--primary-color);
  padding: 20px 0;
  grid-area: copyright;
  color: var(--font-color);
  font-weight: 300;
}

.logo {
  width: 150px;
}

.logo-mobile {
  display: none;
}

.logo-container {
  display: flex;
  cursor: pointer;
}

.header-left-container {
  display: flex;
  align-items: center;
  gap: 20px;
}

.header-container-section {
  max-width: 1270px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
}

.leaderboard-button,
.signin-button {
  font-size: 1rem;
  padding: 5px 15px;
  font-weight: 600;
  border: 2px solid #000;
  border-radius: 18px;
  cursor: pointer;
}

.leaderboard-button>img,
.signin-button>img {
  width: 15px;
  margin-left: 10px;
}

.signin-button {
  border: 2px solid var(--primary-color);
  color: var(--primary-color);
}

.avatar-container,
.avatar-big,
.avatar-medium {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: relative;
}

.avatar-image {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.avatar-level,
.avatar-level-medium {
  display: none;
  position: absolute;
  bottom: -10px;
  right: -40px;
  width: 150px;
}

.avatar-big {
  width: 250px;
  height: 250px;
}

.avatar-medium {
  width: 150px;
  height: 150px;
}

.search-sort-filter-container {
  margin: 30px 0;
}

.search-sort-container {
  display: flex;
  align-items: center;
  gap: 30px;
  width: 100%;
  max-width: 1000px;
  flex-wrap: wrap;
}

.search-input-container {
  display: flex;
  width: 70%;
}

.search-input-section {
  display: flex;
  width: 100%;
}

.search-input {
  background-color: #F2F2F2;
  border: none;
  border-radius: 20px 0 0 20px;
  padding: 10px;
  padding-left: 20px;
  width: 100%;
}

.search-button {
  padding: 10px 25px;
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  background-color: var(--primary-color);
  border: none;
  border-radius: 0 20px 20px 0;
}

.label-text {
  font-weight: 500;
  font-size: 1.1rem;
}

.sort-values {
  background-color: #EBEBEB;
  border-radius: 8px;
  width: 200px;
  height: 40px;
  border: none;
  padding-left: 10px;
  margin-left: 10px;
  font-weight: 500;
}

.difficuilty-js-container {
  display: flex;
  gap: 30px;
  margin-top: 30px;
}

.js-container {
  display: flex;
  align-items: center;
  gap: 30px;
  width: 100%;
  flex-wrap: wrap;
}

.difficuilty-button-container {
  display: grid;
  grid-auto-flow: column;
  gap: 10px;
}

.difficulty-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.difficulty-button {
  padding: 10px 15px;
  background-color: #fff;
  border: 1px solid #d0dae1;
  font-size: 1.3rem;
}

.difficulty-button-active {
  padding: 10px 15px;
  border: 1px solid #d0dae1;
  background-color: #C6F4FE;
}

.difficuilty-symbol {
  height: 20px;
}

/* challenge section styles */
.challenge-main-section {
  display: grid;
  margin-top: 30px;
  grid-template-columns: 700px 1fr;
  gap: 20px;
}

.challenge-button-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px 10px;
  margin: 30px 0;
  max-width: 500px;
}

.common-button,
.figma-button,
.download-button,
.profile-button {
  color: #fff;
  background-color: var(--primary-color);
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  text-align: center;
}

.profile-button:hover {
  background-color: #fff;
  color: var(--primary-color);
}

.figma-button {
  background-color: #C6F4FE;
  color: var(--font-color);
}

.download-button {
  background-color: #fff;
  color: var(--font-color);
  border: 1px solid var(--primary-color);
}

.download-icon {
  height: 15px;
  margin-left: 5px;
}

.submit-button {
  grid-area: 2 / span 2;
}

.challenge-card-description {
  margin-top: 30px;
  color: var(--font-color);
}

.leaderboard-container {
  margin: 30px 0;
}

.leaderboard-title {
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}

.leaderboard-list-item-rank {
  width: 3ch;
}

.leaderboard-list-item-name {
  cursor: pointer;
}

.leaderboard-list-item-left {
  display: flex;
  gap: 10px;
  align-items: center;
}

.leaderboard-rank-emblem-container {
  width: 80px;
  text-align: center;
}

.leaderboard-rank-emblem {
  height: 40px;
}

.leaderboard-list-item,
.leaderboard-list-bronze,
.leaderboard-list-silver,
.leaderboard-list-gold {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  justify-content: space-between;
  padding: 5px 15px;
}

.leaderboard-list-bronze {
  border: 2px solid #cd7f32;
}

.leaderboard-list-silver {
  border: 2px solid #C0C0C0;
}

.leaderboard-list-gold {
  border: 2px solid #FFD700;
}

.leaderboard-list-item {
  border-bottom: 2px solid #E8E6E6;
}

.leaderboard-list-item-right {
  padding-left: 20px;
}

.profile-list-container {
  display: grid;
  gap: 10px;
  margin: 20px 0;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
}

.profile-list-title {
  font-size: 1.5rem;
  font-weight: bold;
}

.profileview-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  place-items: center;
  margin: 30px 0;
}

.profile-details {
  display: flex;
  align-items: center;
  gap: 20px;
}

.profile-name {
  font-size: 1.5rem;
  font-weight: bold;
}

.member-since {
  font-size: 0.9rem;
  font-weight: 200;
  margin-bottom: 10px;
}

.profile-score {
  font-size: 1.1rem;
}

.profile-rank {
  font-size: 1.5rem;
}

.score-label {
  font-size: 0.9rem;
  font-weight: 200;
  margin-right: 5px;
}

.profile-level {
  text-align: center;
}

.profile-level-title {
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
}

.github-button,
.signout-button {
  color: #fff;
  background-color: #000;
  padding: 5px 15px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
}

.signout-button {
  color: #F00;
  background-color: #fff;
  border: 1px solid #F00;
}

.signout-button:hover {
  background-color: #F00;
  color: #fff;
  border: none;
}

.popup-container {
  width: 100%;
  height: 100%;
  display: grid;
  position: fixed;
  background-color: #00000094;
  top: 0;
  left: 0;
  z-index: 5;
}

.signin-button-container {
  max-width: 700px;
  margin: auto;
  display: grid;
  place-items: center;
  padding: 50px;
  background-color: white;
  border-radius: 10px;
}

.sign-out-container {
  display: grid;
  place-items: center;
  gap: 30px;
}


.github-icon {
  margin-right: 20px;
}

.github-button {
  padding: 10px;
}

a {
  text-decoration: none;
  color: #fff;
}

.submit-challenge-container {
  width: 100%;
  max-width: 700px;
  padding: 40px;
  background-color: #fff;
  border-radius: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.submit-form-container {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  margin-bottom: 30px;
}

.form-input,
.form-textarea {
  border: 1px solid #948d8d;
  border-radius: 5px;
  padding: 10px;
}

.feedback-title {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 20px;
}

.submit-button-container {
  text-align: right;
}

.form-textarea {
  height: 100px;
  padding: 10px;
  resize: none;
}

.procedure-container {
  margin-top: 30px;
}

.step-container {
  margin-top: 20px;
}

.step-title {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.procedure-title {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 20px;
  color: var(--font-color);
}

.step-info {
  margin: 10px 0;
  color: var(--font-color);
}

.procedure-image-container {
  width: 100%;
  height: auto;
  max-width: 700px;
  border: 1px solid #000;
}

.procedure-image {
  width: 100%;
  height: 100%;
}

.code-block {
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  padding: 10px;
  padding-left: 30px;
  border-radius: 5px;
  margin-bottom: 10px;
  width: 100%;
  max-width: 700px;
}

.profile-popup-container {
  width: 100%;
  max-width: 800px;
  margin: auto;
  display: grid;
  position: fixed;
  background-color: #fff;
  top: 84px;
  right: 30px;
  z-index: 5;
  padding: 30px;
  box-shadow: 2px 1px 8px 3px #cccccca6;
}

.profile-button-container {
  margin: 30px 20px;
}

.profile-popup-button {
  border-bottom: 1px solid #000;
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.error-screen-popup {
  background-color: #fff;
  border-radius: 5px;
  display: grid;
  place-items: center;
  padding: 30px;
  width: 100%;
  height: 300px;
  max-width: 400px;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.error-screen-popup-content {
  font-size: 3rem;
  font-weight: bold;
  color: var(--font-color);
}

.error-symbol {
  width: 50px;
}

.close-button-container {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.close-button {
  width: 20px;
}

.page-not-found-container {
  display: grid;
  place-items: center;
  gap: 30px;
  margin-top: 30px;
  grid-template-columns: repeat(2, 1fr);
}

.page-not-found-content {
  display: grid;
  gap: 20px;
}

.page-not-found-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--font-color);
}

.page-not-found-sub {
  font-size: 1.2rem;
  font-weight: bold;
  color: var(--font-color);
}

.back-to-home-btn {
  display: flex;
  font-size: 1rem;
  cursor: pointer;
  color: #094E8E;
  align-items: center;
  gap: 10px;
}

.error-message {
  font-size: 1rem;
  text-align: center;
  color: #F00;
  margin-bottom: 20px;
}

.pagination-container {
  display: flex;
  align-items: center;
}

.page-button-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
  margin: 20px auto;
}

.page-button,
.page-button-disabled {
  color: var(--primary-color);
  font-size: 1rem;
  cursor: pointer;
}

.page-button-disabled {
  color: #ccc;
  cursor: default;
}

.setting-icon-container {
  display: none;
}

.js-button-container {
  width: 50px;
  height: 25px;
  border: solid 2px #ccc;
  border-radius: 17.5px;
  position: relative;
}

.js-button-toggler {
  position: absolute;
  top: -5.5px;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  transition: left 0.3s ease;
  left: -8px;
  background-color: #ccc;
}

.js-button-container--active {
  border-color: var(--primary-color);
}

.js-button-container--active .js-button-toggler {
  left: 24px;
  background-color: var(--primary-color);
}

.sponser-info-container {
  display: grid;
  grid-auto-flow: column;
  place-items: center;
  gap: 30px;
  margin-top: 30px;
  box-shadow: 0px -4px 10px rgba(111, 111, 111, 0.1), 0px 4px 10px rgba(111, 111, 111, 0.1);
  position: relative;
  left: 50pX;
  width: calc(100% - 50px);
  top: 50px;
  margin-bottom: 60px;
}

.sponser-info {
  padding: 10px;
}

.sponser-logo {
  position: relative;
  top: -50px;
  left: -50px;
}

.sponser-title {
  font-size: 1.2rem;
  font-weight: bold;
  margin: 20px 0;
  color: var(--primary-color);
}

.sponser-name {
  font-size: 1.4rem;
  font-weight: 600;
  margin: 20px 0;
}

.sponser-sub-title {
  font-size: 1rem;
  font-weight: lighter;
  margin: 20px 0;
  color: var(--font-color);
}





@media screen and (max-width: 1040px) {
  .challenge-main-section {
    grid-template-columns: 1fr;
  }

}

@media screen and (max-width: 860px) {
  .profile-popup-container {
    right: 0;
  }



}

@media screen and (max-width: 480px) {


  .setting-icon-container {
    display: block;
  }

  .sponser-info-container {
    grid-auto-flow: row;
    position: unset;
    width: unset;
  }

  .sponser-logo {
    position: unset;
  }


  .dashboard-main-container {
    top: 70px;
  }

  .leaderboard-text {
    display: none;
  }

  .logo {
    display: none;
  }

  .logo-mobile {
    display: block;
    width: 35px;
  }

  .container {
    padding: 0 5px;
  }

  .leaderboard-button,
  .signin-button {
    border: none;
  }



  .leaderboard-button>img,
  .signin-button>img {
    width: 25px;
    margin: 0;
  }

  .leaderboard-icon {
    width: 50px;
  }

  .leaderboard-rank-emblem-container {
    width: 60px;
  }

  .leaderboard-rank-emblem {
    height: 40px;
  }

  .leaderboard-list-item-rank {
    margin-right: 0;
    width: 2ch;
  }

  .search-button {
    font-size: 0.8rem;
    padding: 5px 10px;
  }

  .difficuilty-symbol {
    height: 15px;
  }

  .difficulty-button {
    padding: 5px 10px;
    font-size: 1rem;
  }

  .challenge-list-container {
    grid-template-columns: 1fr;
  }

  .profile-level-image,
  .profile-level-title {
    display: none;
  }

  .avatar-level,
  .avatar-level-medium {
    display: block;
  }

  .avatar-level-medium {
    width: 100px;
    height: 100px;
    bottom: -30px;
    right: -20px;
  }


  .profile-details {
    flex-direction: column;
    text-align: center;
  }

  .profileview-container {
    grid-template-columns: 1fr;
  }

  .profile-popup-container {
    right: 0;
    top: 60px
  }



  .search-input-container {
    width: 100%;
    gap: 10%;
  }



  .footer-main-section {
    grid-template-areas: "sponser social"
      "copyright copyright";
  }
}

@media screen and (max-width: 350px) {
  .challenge-card-sub-container {
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
  }

  .challenge-card-date {
    text-align: left;
  }

  .leaderboard-rank-emblem-container {
    width: 25px;
  }

  .leaderboard-rank-emblem {
    height: 20px;
  }

  .leaderboard-list-item,
  .leaderboard-list-bronze,
  .leaderboard-list-silver,
  .leaderboard-list-gold {
    padding: 5px 5px;
  }

  .difficuilty-js-container {
    flex-wrap: wrap;
  }

}