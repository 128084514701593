.mark-it-container{
    background-color: #ddd;
    height: 100vh;
    position: relative;
}

.mark-it-page-container{
    display: grid;
    place-items: center;
    height: 100%;
    position: absolute;
    width: 100%;
    opacity: 0;
    pointer-events: none;
    top: 0;
    left: 0;
}

.page-container-active {
    opacity: 1;
    pointer-events: all;
}

.mark-it-container-home-box{
    width: 100%;
    background-color: #fff;
    max-width: 500px;
    padding: 50px 30px;
    box-shadow: 2px 2px 5px 0px #00000012;
}

.mark-it-container h1{
    text-align: center;
    margin-bottom: 40px;

}


.mark-it-input-field{
    padding-bottom: 25px;
    position: relative;
}

.mark-it-input-field input{
    width: 100%;
    padding: 20px 20px 10px 20px;
    border: none;
    border-bottom: 1px solid #ccc7;
    outline: none;
    background-color: #eee4;
    font-size: 18px;
}

.mark-it-input-field input:focus ~ label, .mark-it-filled-input label{
    top:5px;
    font-size: 12px;
    left: 10px;
}

.mark-it-input-field label{
    color: #777;
    position: absolute;
    left: 20px;
    top: 15px;
    pointer-events: none;
    transition: 0.2s ease-in-out;
}

.mark-it-button button{
    width: 100%;
    padding: 10px;
    font-size: 1.2rem;
    font-weight: 500;
    cursor: pointer;
    background-color: #094e8e;
    border: none;
    color: #fff;
}

.mark-it-link{
    text-align: center;
    color: #094e8e;
    cursor: pointer;
    font-weight: 500;
}

.mark-it-dashboard-container{
    width: 100%;
    height: 100%;
}

.mark-it-dashboard-container header{
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 15px 20px;
    background-color: #fff;
    box-shadow: 2px 2px 5px 0px #00000012;
}

.mark-it-dashboard-container header h2{
    text-align: left;
}

.mark-it-dashboard-content-section{
    background-color: #fff;
    box-shadow: 2px 2px 5px 0px #00000012;
    margin: 40px 20px;
    min-height: 500px;
}

.mark-it-dashboard-content-header{
    display: grid;
    grid-auto-flow: column;
    justify-content: flex-start;
    border-bottom: solid 1px #ccc7;
}

.mark-it-dashboard-tabs{
    padding: 10px 15px;
    border-right: solid 1px #ccc7;
    cursor: pointer;
}

.mark-it-dashboard-tabs:hover{
    background-color: #ccc7;
}

.mark-it-dashboard-tabs-active{
    background-color: #094e8e;
    color: #fff;
}

.mark-it-dashboard-tabs-active:hover{
    background-color: #094e8ee4;
}

.mark-it-dashboard-content-body{
    padding: 40px;
    display: grid;
    height: 100%;
}

.mark-it-dashboard-add-task-button{
    display: grid;
    place-items: center;
    width: 150px;
    height: 60px;
    border: solid 1px #ccc7;
    background-color: #fff;
    box-shadow: 2px 2px 3px 0px #00000014;
    border-radius: 5px;
    font-size: 1.2rem;
    cursor: pointer;
    transition: 0.2s ease-in-out;
}

.mark-it-dashboard-add-task-button:hover{
    background-color: #ccc4;
}

.mark-it-popup{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
}

.mark-it-popup-container{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 700px;
    background-color: #fff;
    box-shadow: 2px 2px 5px 0px #00000012;
}

.mark-it-popup-container header{
    background-color: #0f64b3;
    padding: 10px;
    color: #fff;
    display: flex;
    justify-content: space-between;
}

.mark-it-popup-container header h3{
    font-weight: 400;
}

.mark-it-popup-container header div{
    cursor: pointer;
    transition: 0.2s ease-in-out;
}

.mark-it-popup-container header div:hover{
    color: #fff9;
}


.mark-it-popup-container main{
    padding: 40px 20px;
}

.mark-it-popup-container main form{
    max-width: 500px;
    margin: auto;
}