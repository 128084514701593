.test-mate-screen{
    background-image: url(./assets/images/back.jpeg);
    background-size: cover;
    display: flex;
    align-items: center;
    min-height: 100vh;
    overflow: auto;
    justify-content: center;
    padding: 100px 25px;
    position: relative;
}

.image-logo{
    position: absolute;
    width: 50%;
    max-width: 300px;
    top: 20px;
    right: 20px;
}

.messagebox{
    position: relative;
    display: grid;
    max-width: 900px;
    width: 100%;
    min-height: 430px;
    background-color: white;
    border-radius: 15px;
    box-shadow: 0px 4px 6px #A1E9FD;
    overflow: hidden;
    transform: all 0.5s ease-in-out;
}

.starting-page-container{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 30px;
    align-items: center;
    gap: 50px;
    height: 100%;
}

.test-mate-primary-button{
    min-width: 216px;
    height: 49px;
    border-radius: 50px;
    background-color: #9b59b6;
    border: none;
    color: white;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    font-size: 1.2rem;
}

.test-mate-primary-button-disabled{
    opacity: 0.5;
}

.page-container{
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    pointer-events: none;
}

.page-container-active{
    opacity: 1;
    pointer-events: all;
    position: static;
}

.test-mate-primary-button:hover{
    background-color: #8e44ad;
}

.test-mate-title{
    font-size: 30px;
    font-weight: bold;
    color: #323232;
}

.test-mate-normal-text{
    font-size: 16px;
    color: #696969;
    max-width: 700px;
}


.instruction-page-container{
    height: 100%;
    padding: 30px;
    display: grid;
    place-items: center;
    gap: 50px;
    align-content: center;
}

.instructions-list-container{
    display: grid;
    gap: 10px;
}

.question-screen-container{
    display: grid;
    gap: 50px;
    place-items: center;
    padding: 30px;
    height: 100%;
}

.test-mate-question{
    max-width: 750px;
    font-size: 22px;
    font-weight: medium;
}

.test-mate-options{
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 30px;
    gap: 20px;
    margin: auto;
    max-width: 700px;
    font-weight: bold;
    font-size: 22px;
}

.question-screen-header{
    display: grid;
    grid-auto-flow: column;
    width: 100%;
    justify-content: space-between;
}

.question-number, .question-time-remaining{
    background: #ADF0FF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    padding: 8px 15px;
    font-size: 2rem;
    font-weight: bold;
}

.test-app-skip-button{
    color: #8e44ad;
    cursor: pointer;
    font-weight: bold;
}

.test-mate-result-screen{
    padding: 20px;
    max-width: 1000px;
    margin: auto;
    display: grid;
    grid-template-columns: 1fr 1fr 0.5fr 0.5fr;
    gap: 2px;
}

.result-body{
    font-weight: bold;
}

.result-header, .result-body{
    text-align: center;
    padding: 10px;
    background-color:   #eaeaea;
}

.test-mate-input{
    padding: 8px 15px;
    font-size: 25px;
    max-width: 500px;
    width: 100%;
}

.start-input-container{
    width: 100%;
    text-align: center;
}

.test-app-check-box-container input[type=radio] {
    border: 0px;
    width: 2em;
    height: 2em;
}

.test-app-check-box-container{
    display: flex;
}