html, body, #root, .App{
    min-height: 100%;
    height: 100%;
}
.iq-test-screen{
    height: 100%;
    overflow: auto;
    background: linear-gradient(180deg, #87D3FE 72.27%, #D3DFD3 100%);
}

:root{
    --white: #fff;
    --black: #000;
    --blue: #085994;
    --dark-blue: #093763;
    --light-gray: #bababa;
    --gray: #8c8c8c;
    --dark-gray: #636363;
}

.iq-test-container{
    padding: 10px 10px;
    max-width: 1300px;
    margin: auto;
    min-height: 100%;
    display: grid;
    grid-template-rows: 70px 1fr 70px;
    align-items: center;
}

.iq-test-logo{
    max-width: 200px;
    width: 50%;
    height: 38px;
}

.iq-test-window{
    background-color: var(--white);
    height: 100%;
}

.iq-home-container{
    display: grid;
    height: 100%;
    grid-template-columns: 3fr 2fr;
}

.iq-home-left-section{
    background-image: url(./Assets/home-background.jpg);
    background-size: cover;
    position: relative;
}

.iq-home-left-section::after{
    top: 0;
    left: 0;
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: var(--dark-blue);
    opacity: 0.95;
}

.iq-home-right-section{
    display: grid;
    justify-items: center;
    padding: 40px 10px;
    grid-auto-rows: 1fr auto auto;
    gap: 30px;
    align-items: center;
}

.iq-home-right-section>img{
    width: 60%;
}

.iq-home-button{
    padding: 10px 45px;
    background-color: var(--blue);
    color: var(--white);
    border-radius: 40px;
    cursor: pointer;
    border: solid 2px var(--blue);
    transition: background-color 0.3s ease-in-out;
}

.iq-home-button:hover{
    background-color: var(--white);
    color: var(--blue);

}

.iq-home-label{
    color: var(--blue);
    font-size: 0.9rem;
}

.iq-home-left-container{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    color: var(--white);
    padding: 20px;
    display: grid;
    align-content: center;
    justify-content: center;
    gap: 10px;
}

.iq-home-text, .iq-home-title{
    max-width: 500px;
}

.iq-home-title{
    font-size: 1.5rem;
    font-weight: 500;
    text-align: center;
    padding-bottom: 25px;
}

.iq-home-text{
    font-size: 0.9rem;
    color: var(--light-gray);
}

.iq-home-text-list-item{
    padding-left: 15px;
    position: relative;
}

.iq-home-text-list-item::before{
    content: "";
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: var(--light-gray);
    left: 0px;
    top: 5px;
}

.progress-ring__circle {
    transition: stroke-dashoffset 0.35s;
    transform: rotate(90deg);
    transform-origin: 50% 50%;
}

.iq-test-question-container{
    padding: 20px;
}

.iq-test-question-header{
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;
    align-items: center;
}

.iq-test-question-header{
    font-size: 1.4rem;
    font-weight: 600;
}

.question-container{
    max-width: 820px;
    margin: auto;
    padding: 0 60px;
    margin-top: 30px;
    margin-bottom: 20px;
    position: relative;
    gap: 20px;
}

.iq-test-question-number-circle{
    padding: 5px;
    border: solid 2px var(--dark-blue);
    border-radius: 50px;
    font-size: 1.1rem;
    font-weight: 600;
    color: var(--dark-blue);
    min-width: 35px;
    text-align: center;
    position: absolute;
    left: 10px;
}

.iq-text-question-number{
    font-size: 1.4rem;
    font-weight: 600;
    max-width: 700px;
    margin: auto;
}

.iq-test-question-text{
    font-size: 1.2rem;
    font-weight: 600;
}

.iq-test-question-answers{
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    padding: 30px;
    justify-content: center;
    max-width: 1000px;
    margin: auto;
}

.answer-container{
    display: flex;
    gap: 10px;
    align-items: center;
}

.iq-test-question-option input{
    width: 20px;
    height: 20px;
}

.iq-test-option-letter{
    font-size: 1.2rem;
    font-weight: 600;
}

.iq-test-select-container{
    width: 100%;
    max-width: 250px;
    display: flex;
    gap: 10px;
    flex-direction: column;
}

.iq-test-select-container .iq-test-answer-text{
    font-size: 1.2rem;
    font-weight: 600;
    text-align: center;
}

.iq-test-select-box-container{
    display: grid;
}

.iq-test-select-box-container select{
    padding: 5px 10px;
    font-size: 1.1rem;
    font-weight: 500;
}

.iq-test-text-box-container{
    display: flex;
    gap: 40px;
}

.iq-test-text-box input{
    padding: 5px 10px;
    font-size: 1.1rem;
    font-weight: 500;
}

.iq-test-text-area-container{
    width: 100%;
    max-width: 700px;
}

.iq-test-text-area-container textarea{
    padding: 5px 10px;
    font-size: 1.1rem;
    font-weight: 500;
    width: 100%;
    resize: none;
}

.iq-test-answer-text{
    font-size: 1.2rem;
    font-weight: 600;
}

.iq-test-puzzle-table-container{
    background-color: var(--black);
    display: grid;
    gap: 2px;
    padding: 2px;
}

.iq-test-puzzle-table-row{
    display: flex;
    gap: 2px;
}
.iq-test-puzzle-table-cell{
    width: 30px;
    height: 30px;
    display: grid;
    place-items: center;
    background-color: var(--white);
    font-size: 1.1rem;
    font-weight: 600;
}

.iq-test-puzzle-table-cell input{
    width: 100%;
    padding: 4px;
    text-align: center;
    font-size: 1.1rem;
    border: none;
    outline: none;
    font-weight: 600;
    color: var(--dark-gray);
}

.iq-test-question-footer{
    display: flex;
    justify-content: flex-end;
    margin: 20px auto;
    max-width: 800px;
    gap: 20px;
    align-items: center;
}
.iq-test-question-footer-skip{
    color: var(--blue);
    cursor: pointer;
}

.iq-test-fill-in-blank-container{
    line-height: 2.5rem;
    max-width: 700px;
    margin: auto;
}

.iq-test-fill-in-blank-input{
    border: none;
    border-bottom: solid 1px var(--dark-gray);
    outline: none;
    width: 120px;
    padding: 1px 2px 0px;
    text-align: center;
    font-size: 1.1rem;
}

.iq-test-text-box input{
    width: 80px;
    text-align: center;
}

.cam-warning{
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
    color: var(--dark-gray);
}

.iq-test-finish-screen{
    display: grid;
    background-image: url(./Assets/thankyou.png);
    width: 100%;
    height: 100%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #f2f2f2;
}